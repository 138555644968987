<script>
export default {
  data() {
    return {
      menuItems: [
        {title: "Home", link: "#home"},
        {title: "Services", link: "#services"},
        {title: "Products", link: "#products"},
        {title: "Contacts", link: "#contacts"},
      ],
    }
  }
}
</script>

<template>
  <footer class="py-[60px] md:py-[80px] max-w-[1110px] mx-auto px-[16px] xl:px-0">
    <div class="w-full mb-[36px]">
      <img src="@/assets/Monview_white_logo.svg" alt="MonView" class="mx-auto">
    </div>
    <div class="flex footer_menu gap-[8px] md:gap-[16px] items-center justify-center mb-[70px]">
      <a class="footer_menu__item p-[8px] uppercase text-white" v-for="(item, index) in menuItems" :key="index" :href="item.link">{{ item.title }}</a>
    </div>
    <div class="flex flex-col md:flex-row items-center justify-between opacity-50">
      <div class="order-3 md:order-1 text-white">
        <p class="menu-font">© 2024 by Monview. All rights reserved.</p>
      </div>
      <div class="order-1 md:order-2 mb-[24px] md:mb-0">
        <a href="https://www.linkedin.com/company/monview-tv/about/" target="_blank">
          <img src="@/assets/LinkedIn.svg" alt="MonView LinkedIn">
        </a>
      </div>
      <div class="order-2 md:order-3 flex gap-[34px] justify-between md:gap-[24px] text-white  mb-[16px] md:mb-0">
        <router-link to="/privacy-policy" target="_blank" class="menu-font">Privacy Policy</router-link>
        <router-link to="/terms-conditions" target="_blank"  class="menu-font">Terms & Conditions</router-link>
      </div>
    </div>
  </footer>
</template>

<style scoped>
.footer_menu {
  .footer_menu__item:hover {
    color: #EB3756;
  }
}
</style>